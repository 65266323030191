@import "./palette";
@import "./mixins";

.button {
  border-radius: 1rem;
  border: 0;
  font-size: 1.2em;
  height: 2rem;
  line-height: 1.5rem;

  width: 18rem;
  border: 1px solid black;
  color: rgb(236, 236, 226);
  margin: 1rem;
  background-color: transparent;

  &--default {
    @extend .button;
    background-color: $button-default;
  }
  &--default:hover {
    background-color: $button-default-hover;
  }

  &--submit {
    @extend .button;
    width: 9rem;
    border: 3.5px solid white;
    background-color: rgba($color: #000000, $alpha: 0);
    z-index: 2;
    transition: 0.3s;
  }
  &--submit:hover {
    background-color: $button-confirm-hover;
    color: black;
    box-shadow: inset 0 0 10px #55ff71, 0 0 20px #55ff71;
  }

  &--cancel {
    @extend .button;
    background-color: $button-cancel;
    transition: 0.3s;
  }
  &--cancel:hover {
    background-color: $button-cancel-hover;
  }

  &--welcome {
    @extend .button;
    position: relative;
    // top: 88%;
    // left: 50%;
    transform: translate(-60%, -50%);

    border: 3.5px solid white;
    background-color: rgba($color: #000000, $alpha: 0);

    z-index: 2;
    transition: 0.3s;
  }
  &--welcome:hover {
    box-shadow: inset 0 0 10px #ff55f7, 0 0 20px #ff55f7;
    color: #ff55f7;
  }

  &:disabled {
    border-color: rgb(92, 86, 86);
    background-color: $button-disabled;
    color: gray;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
}
@media all and (max-width: 650px) {
  .button {
    border-radius: 0.8rem;
    font-size: 0.8em;
    height: 1.4rem;
    line-height: 1rem;
    border: 2.5px solid white;
    width: 9rem;
    margin: 0.3rem;
  }
}
@media all and (max-width: 400px) {
  .button {
    border-radius: 0.7rem;
    font-size: 0.6em;
    height: 1.3rem;
    line-height: 0.5rem;
    border: 1.7px solid white;
    width: 7rem;
    margin: 0.3rem;
  }
}
