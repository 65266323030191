// -----COLOR SCHEME-----
$nav-bar-underglow: #36a5ffc7;
$nav-bar-color: rgb(255, 255, 255);
$nav-link-hover-glow: #ff55f7;

$side-bar-background: #82cff4;

// -----BUTTONS-----
$button-default: #ffbb00;
$button-default-hover: #ce9700;

$button-confirm: transparent;
$button-confirm-hover: #a9f5a9;

$button-cancel: transparent;
$button-cancel-hover: #f5a9a9;

$button-disabled: rgb(243, 240, 240);

// -----CHECKBOX-----
$checkbox-default: #82d0f4b2;
$checkbox-default-hover: $button-default-hover;
$checkbox-default-checked: $button-default;
