@mixin center-in-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-in-page {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}