@import "./mixins";
@import "./palette";

.dark-tiger {
  @include center-in-parent;
  // width: 800px;
  height: 60vh;
  z-index: 0;
}
.gold-tiger {
  @include center-in-parent;
  //width: 300px;
  height: 30vh;
  opacity: 0.6;
}
.gold-tiger:hover {
  opacity: 1;
  z-index: 1;
}
.searchform {
  display: flex;
  flex-direction: row;
}
h1 {
  @include center-in-parent;
  font-size: 3em;
  color: rgb(177, 73, 73);
}
h6 {
  @include center-in-parent;
  color: rgb(177, 73, 73);
}
.search-box {
  @include center-in-parent;
  display: flex;
  flex-direction: row;
}
.h3 {
  margin-top: 1em;
}
.h3 p {
  font-family: Geneva, Verdana, sans-serif;
  color: snow;
  font-size: 0.7em;
  width: 42em;
  text-align: justify;
  text-justify: inter-word;
  margin: 0;
}
.yazi {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // @include center-in-parent;
}
.yanyazi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include center-in-parent;
}

.allpage {
  background-color: black;
  display: flex;
  flex-direction: column;
}

.buttig {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttig-projects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tesla-img {
  opacity: 0.6;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 10px #ff55f7, 0 0 20px #ff55f7;
  width: auto;
  height: 35vh;
  @include center-in-parent;
}
.tesla-img:hover {
  opacity: 0.8;
  box-shadow: inset 0 0 30px white, 0 0 40px white;
}
.project-title {
  // z-index: 1;
  background-color: black;
}

.project-links {
  display: flex;
  flex-direction: column;
  margin: 0.8em;
  //margin-right: 0.8em;
  font-family: "Kalam", cursive;
  color: white;
  @include center-in-parent;
  h4 {
    font-size: 2em;
  }
  p {
    width: 17em;
    font-size: 1.3em;
  }
  a {
    color: white;
  }
  a:hover {
    color: rgb(177, 73, 73);
  }
}

.resume-logo {
  height: 20vh;
  @include center-in-parent;
  position: relative;
  //left: 45%;
  opacity: 0.4;
  margin-top: 20px;
}
.resume-logo:hover {
  opacity: 1;
  box-shadow: inset 0 0 100px white, 0 0 200px #55ff7f;
}

@media (max-width: 1300px) {
  .tesla-img {
    height: 30vh;
  }
  .project-links {
    h4 {
      font-size: 1em;
    }
    p {
      width: 18em;
      font-size: 0.8em;
    }
  }
}
@media all and (max-width: 650px) {
  .dark-tiger {
    height: 40vh;
  }

  h1 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1em;
    width: 8em;
  }

  .gold-tiger {
    display: none;
  }
  .yazi {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1.5em;
  }
  .resume-logo {
    height: 10vh;
    @include center-in-page;
    margin: 0 0 0 0;
  }
  .tesla-img {
    display: none;
  }
  .h3 p {
    color: snow;
    font-size: 0.4em;
    width: 36em;
  }
}
@media all and (max-width: 400px) {
  .buttig-projects {
    flex-direction: column;
  }
  .buttig {
    justify-content: space-around;
  }

  .dark-tiger {
    height: 28vh;
  }
  .h3 p {
    color: snow;
    font-size: 0.4em;
    width: 30em;
  }
  .resume-logo {
    height: 5vh;
    @include center-in-page;
    margin: 0 0 0 0;
  }
}
