@import "./palette";
@import "./mixins";

.nav {
  // position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  background-color: black;
  list-style: none;
  box-shadow: 0px 0px 20px #ff55f7;
  z-index: 3;
  position: sticky;
  top: 0;

  p,
  button {
    padding: 0;
    margin: 0;
  }
  p:hover {
    cursor: default;
  }

  .nav-menu {
    display: flex;
    align-content: center;
    padding-right: 90px;
    .nav-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      list-style: none;
      margin-right: -80px;
    }
    .nav-item-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: "Kalam", cursive;
      font-size: 1.5rem;
      padding-right: 1rem;
    }
    .tiger-with {
      height: 100px;
      position: relative;
      opacity: 0.8;
      margin-left: -109px;
      z-index: -7;
    }
  }

  .navbar-logo {
    justify-content: left;
    font-size: 30 px;
    padding-left: 2em;
  }
  .navbar-logo:hover {
    color: dodgerblue;
  }

  .nav-bar-actions {
    justify-content: flex-end;
  }

  a.nav-link {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
  }

  .nav-button {
    font-family: "Kalam", monospace;
    font-size: 1.5rem;
    // text-decoration: none;
    color: white;
    border: none;
    background-color: black;
    outline: none;
    &:hover {
      cursor: pointer;
      text-shadow: 0px 0px 20px #ff55f7;
      color: #ff55f7;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
}

.title {
  @include center-in-parent;
  font-size: 2.7em;
  color: rgb(177, 73, 73);
  margin: 0px 0px 0px 0px;
}
.title-group {
  @include center-in-parent;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 37%;
}

@media (max-width: 1300px) {
  .title {
    font-size: 2em;
  }

  .nav {
    .nav-button {
      font-size: 1rem;
    }
    .nav-item {
      font-size: 1rem;
    }
  }
}

@media (max-width: 650px) {
  .title-group {
    left: 20%;
  }
  .nav-menu {
    // .nav-button {
    //   font-size: 1rem;
    // }
    .nav-item {
      display: none;
    }
  }
}
@media all and (max-width: 400px) {
  .title-group {
    display: none;
  }
}
