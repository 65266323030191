.footer {
  margin: 6vh 0px 0px 0px;
  min-height: 10em;
  //width: 80%
  .icon {
    font-size: 48px;
    color: whitesmoke;
  }
  .icon:hover {
    color: dodgerblue;
  }
  .footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  @media all and (max-width: 1300px) {
    .icon {
      font-size: 36px;
    }
  }
  @media all and (max-width: 650px) {
    .icon {
      font-size: 30px;
    }
  }
  @media all and (max-width: 400px) {
    .icon {
      font-size: 20px;
    }
  }
}
